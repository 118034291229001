<template>
    <div class="bind-mobile">


        <!-- 綁定郵箱找回 -->
        <van-nav-bar  :title="$t('LAN.bind_mobile')"     left-text="返回"       left-arrow        @click-left="goBack"  />

        
        <van-empty v-if="success"
            class="custom-image"
            :image='require(`@/assets/common/success.png`)'
            :description="$t('LAN.success')"/>

        <!-- 绑定手机找回 -->
        <div class="content" v-else>
            <div class="mobile-tips" > <!--:style="$L(1)"-->
                <p>{{ $t('LAN.bind_mobile_tips') }}</p>
            </div>

            <van-form v-if="type == 'old'" @submit="type = 'new'">
                <van-field
                    left-icon="phone-o"
                    v-model="oldMobile"
                    :label="$t('LAN.mobile') + '(+86)'"
                    :placeholder="$t('LAN.input_old_mobile')"
                    clearable
                    center
                    :rules="[{ validator:validatorMobile, required: true, message: $t('LAN.input_validator_mobile') }]"
                />
                <van-field
                    v-model="oldVerify"
                    :label="$t('LAN.verify_code')"
                    center
                    left-icon="lock"
                    :placeholder="$t('LAN.input_old_verify_mobile')"
                    :rules="[{ required: true, message: $t('LAN.input_old_verify_mobile') }]">
                    <template #button>
                        <van-button size="small" type="primary" color="#f3b134" @click="sendOldVerify" :disabled="oldVerifyDisabled"> {{ oldVerifyBtnText }} </van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">{{$t('LAN.next')}}</van-button>
                </div>
            </van-form>

            <van-form v-else @submit="sendBind">
                <van-field
                    left-icon="phone-o"
                    v-model="mobile"
                    :label="$t('LAN.mobile') + '(+86)'"
                    :placeholder="$t('LAN.input_bind_mobile')"
                    clearable
                    center
                    :rules="[{ validator:validatorMobile, required: true, message: $t('LAN.input_validator_mobile') }]"
                />  

                <van-field
                    v-model="verify"
                    :label="$t('LAN.verify_code')"
                    center
                    left-icon="lock"
                    :placeholder="$t('LAN.input_new_verify_mobile')"
                    :rules="[{ required: true, message:  $t('LAN.input_new_verify_mobile') }]">
                    <template #button>
                        <van-button size="small" type="primary" color="#f3b134" @click="sendVerify" :disabled="verifyDisabled">{{ verifyBtnText }}</van-button>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">{{$t('LAN.bind_now')}}</van-button>
                </div>
            </van-form>

            <div class="mobile-tips" > <!-- :style="$L(1)"-->
                <p>{{$t('LAN.tips')}}</p>
                <p>{{$t('LAN.mobile_tips')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import {  useRouter} from 'vue-router'
import { Toast } from 'vant'
import { requestMobileVerifycode, requestBindMobile } from '@/service/user'
import { validateMobile } from '@/utils/validate';
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
    setup() {

        const { t } = useI18n()
        const store = useStore()
        const router = useRouter()
        const state = reactive({
            success:false,
            type: 'old',
            username: '',
            mobile: '',
            verify: '',
            verifyTime:0,
            verifyDisabled: false,
            verifyBtnText: t('LAN.send_verify_code'),

            oldMobile: '',
            oldVerify: '',
            oldVerifyTime:0,
            oldVerifyDisabled:false,
            oldVerifyBtnText: t('LAN.send_verify_code')
        })
        
        onMounted(() => {
            init()
        });
        
        const init = async () => {
            state.oldMobile = store.getters.mobile || ''
            if (state.oldMobile == '') {
                state.type = "new";
            } 
        }

        const sendOldVerify = async () => {

            if (state.oldMobile == '' || !validatorMobile(state.oldMobile)) {
                Toast(t('LAN.input_validator_mobile'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestMobileVerifycode({mobile:"86" + state.oldMobile});
            state.oldVerifyTime = 30
            onOldVerifyTimer()
            Toast(t('LAN.send_success'))
        }

        const onOldVerifyTimer = () => {
            if (state.oldVerifyTime > 0) {
                state.oldVerifyDisabled = true;
                state.oldVerifyTime --;
                state.oldVerifyBtnText = `${t('LAN.resend')}(${state.oldVerifyTime})`;
                setTimeout(onOldVerifyTimer, 1000);
            } else{
                state.oldVerifyTime = 0;
                state.oldVerifyBtnText = t('LAN.send_verify_code');
                state.oldVerifyDisabled = false;
            }
        }

        const sendVerify = async () => {
            if (state.mobile == '' || !validatorMobile(state.mobile)) {
                Toast(t('LAN.input_validator_mobile'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestMobileVerifycode({mobile:"86" + state.mobile});
            state.verifyTime = 30
            onVerifyTimer()
            Toast(t('LAN.send_success'))
        }

        const onVerifyTimer = () => {
            if (state.verifyTime > 0) {
                state.verifyDisabled = true;
                state.verifyTime --;
                state.verifyBtnText = `${t('LAN.resend')}(${state.verifyTime})`;
                setTimeout(onVerifyTimer, 1000);
            } else{
                state.verifyTime = 0;
                state.oldVerifyBtnText = t('LAN.send_verify_code');
                state.verifyDisabled = false;
            }
        }

        const sendBind = async () => {
            Toast.loading(t('LAN.sending'));
            await requestBindMobile({
                oldVerifyCode:state.oldVerify,
                mobile:state.mobile,
                verifyCode:state.verify
            });
            Toast.success(t('LAN.send_success'))
            state.success = true;
            store.commit('setMobile', state.mobile)
        }

        const validatorMobile = (mobile) => {
            return validateMobile(mobile)
        }

        const goBack = () => {
            router.go(-1)
        }

        return {
            ...toRefs(state),
            sendOldVerify,
            sendVerify,
            validatorMobile,
            sendBind,
            goBack
        }
    },
    components: {

    }
}
</script>

<style lang="less" scoped>
/deep/ .custom-image .van-empty__image {
    width: 100px;
    height: 100px;
}

.bind-mobile {
    height: 100vh;
    .content {
        margin-top: 10px;
    }
}
.mobile-tips {
    margin: 20px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}
</style>